import { PaymentRun, PayrollPeriodPaymentRuns } from '@app/payrolls/payment-runs/payment-run';

export const getLatestUnapprovedOrUnauthorisedPaymentRun = (payrollPeriodPaymentRuns: PayrollPeriodPaymentRuns): PaymentRun | null => {
  if (!payrollPeriodPaymentRuns || !payrollPeriodPaymentRuns.paymentRuns || !payrollPeriodPaymentRuns.paymentRuns.length) return null;

  let paymentRun = sortPaymentRunsByRunNumber(payrollPeriodPaymentRuns.paymentRuns).filter(
    (pr) => pr.paymentAuthorisedDate === null || pr.payrollApprovedDate === null
  );
  if (paymentRun.length > 1) throw new Error('Multiple payment runs found');

  if (paymentRun.length === 0) return null;

  return paymentRun[0];
};

export const getLatestPaymentRunByPriority = (payrollPeriodPaymentRuns: PayrollPeriodPaymentRuns): PaymentRun | null => {
  const unapprovedPaymentRun = getReadyForApprovalPaymentRun(payrollPeriodPaymentRuns);
  const unauthorisedPaymentRun = getReadyForAuthorisationPaymentRun(payrollPeriodPaymentRuns);
  const latestPaymentRun = getLatestPaymentRun(payrollPeriodPaymentRuns);

  if (unapprovedPaymentRun) return unapprovedPaymentRun;

  if (unauthorisedPaymentRun) return unauthorisedPaymentRun;

  return latestPaymentRun;
};

export const getReadyForAuthorisationPaymentRun = (payrollPeriodPaymentRuns: PayrollPeriodPaymentRuns): PaymentRun | null => {
  if (!payrollPeriodPaymentRuns || !payrollPeriodPaymentRuns.paymentRuns || !payrollPeriodPaymentRuns.paymentRuns.length) return null;

  let paymentRun = payrollPeriodPaymentRuns.paymentRuns.filter(
    (pr) => pr.paymentAuthorisedDate === null && pr.payrollApprovedDate !== null
  );
  if (paymentRun.length > 1) throw new Error('Multiple payment runs found');

  if (paymentRun.length === 0) return null;

  return paymentRun[0];
};

export const getReadyForApprovalPaymentRun = (payrollPeriodPaymentRuns: PayrollPeriodPaymentRuns): PaymentRun | null => {
  if (!payrollPeriodPaymentRuns || !payrollPeriodPaymentRuns.paymentRuns || !payrollPeriodPaymentRuns.paymentRuns.length) return null;

  let paymentRun = payrollPeriodPaymentRuns.paymentRuns.filter(
    (pr) => pr.paymentAuthorisedDate === null && pr.payrollApprovedDate === null
  );
  if (paymentRun.length > 1) throw new Error('Multiple payment runs found');

  if (paymentRun.length === 0) return null;

  return paymentRun[0];
};

export const getLatestPaymentRun = (payrollPeriodPaymentRuns: PayrollPeriodPaymentRuns): PaymentRun | null => {
  if (!payrollPeriodPaymentRuns || !payrollPeriodPaymentRuns.paymentRuns || !payrollPeriodPaymentRuns.paymentRuns.length) return null;

  let paymentRun = sortPaymentRunsByRunNumber(payrollPeriodPaymentRuns.paymentRuns);

  if (paymentRun.length === 0) return null;

  return paymentRun[0];
};

export const sortPaymentRunsByRunNumber = (paymentRuns: PaymentRun[]): PaymentRun[] => {
  return paymentRuns.sort((a, b) => (a.payrollRunNumber === b.payrollRunNumber ? 0 : a.payrollRunNumber < b.payrollRunNumber ? 1 : -1));
};
