import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { formatDate } from '@utils/date-utils';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { StatusDatePillConfig } from '@app/payrolls/payment-runs/steps/step-pill/payment-run-step-pill.component';
import moment from 'moment';

export type PaymentRunStep = 'handover' | 'approval' | 'authorisation';
export interface PaymentRunStepStatus {
  isPayrollLocked: boolean;
  step: PaymentRunStep;
  stepCompleted: boolean;
  showAwaitingText: boolean;
  actionedDate?: string;
  completeByDate?: string;
  showActionedByUser: boolean;
  actionedByUser?: string;
  useDarkMode?: boolean;
  rightAlign?: boolean;
  shrinkPadding?: boolean;
  fullWidth?: boolean;
}

@Component({
  selector: 'app-payment-run-steps',
  templateUrl: './payment-run-steps.component.html',
  styleUrl: './payment-run-steps.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentRunStepsComponent {
  bureauHandlesBacs = this.securityStateService.activeMembership.bacsProcessor === 'Bureau';
  @Input() handoverPillConfig: StatusDatePillConfig;
  @Input() approvalPillConfig: StatusDatePillConfig;
  @Input() authorisationPillConfig: StatusDatePillConfig;

  constructor(private securityStateService: ActiveSecurityContextStateService) {}

  static createStepPillConfig(stepStatus: PaymentRunStepStatus) {
    let {
      step,
      stepCompleted,
      showAwaitingText,
      actionedDate,
      completeByDate,
      showActionedByUser,
      actionedByUser,
      useDarkMode,
      rightAlign,
      shrinkPadding,
      fullWidth
    } = stepStatus;

    if (!stepStatus.isPayrollLocked) {
      actionedDate = null;
      stepCompleted = false;
      showActionedByUser = false;
    }

    if (fullWidth == undefined) fullWidth = true;

    let pillConfig: StatusDatePillConfig = {
      stepCompleted,
      showAwaitingText,
      showActionedByUser,
      actionedByUser,
      ink: useDarkMode ? 'ink-25' : 'ink-45',
      textColor: useDarkMode ? 'ink-white' : 'ink-45',
      pillSize: 'small',
      display: 'inline-block',
      usePillOutline: true,
      useTransparentBackground: true,
      width100Percent: fullWidth,
      rightAlign,
      shrinkPadding,
      actionedDate,
      completeByDate: completeByDate,
      date:
        actionedDate && stepCompleted
          ? 'on ' + formatDate(actionedDate, 'DD MMM YYYY')
          : completeByDate
            ? 'before ' + formatDate(completeByDate, 'DD MMM YYYY, HH:mm')
            : ''
    };

    if (stepCompleted) {
      pillConfig.ink = 'primary';
      pillConfig.textColor = useDarkMode ? 'ink-white' : 'ink-85';
      pillConfig.backgroundColor = 'system-green';
      pillConfig.usePillOutline = false;
      pillConfig.showAwaitingText = false;
      pillConfig.useTransparentBackground = false;
    } else if (actionedDate == null && completeByDate != null && moment(completeByDate) < moment()) {
      pillConfig.ink = useDarkMode ? 'ink-white' : 'system-red';
      pillConfig.backgroundColor = useDarkMode ? 'ink-red' : 'none';
      pillConfig.textColor = useDarkMode ? 'ink-white' : 'system-red';
      pillConfig.useTransparentBackground = !useDarkMode;
    }

    switch (step) {
      case 'handover':
        pillConfig.label = stepCompleted ? 'Submitted' : 'Submission';
        pillConfig.leftIcon = 'send';
        break;
      case 'approval':
        pillConfig.label = stepCompleted ? 'Approved' : 'Approval';
        pillConfig.leftIcon = 'done_all';
        break;
      case 'authorisation':
        pillConfig.label = stepCompleted ? 'Payment authorised' : 'Payment authorisation';
        pillConfig.leftIcon = 'order_approve';
        pillConfig.useIconOutlined = true;
        pillConfig.useIconSymbol = true;
        break;
    }

    return pillConfig;
  }
}
