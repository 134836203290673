<div class="pill-list-container">
  <div class="pill-list" [ngClass]="{ 'pill-list-background': handoverPillConfig || bureauHandlesBacs }">
    <app-payment-run-step-pill
      tid="payroll-handover-pill"
      [attr.data-step-complete]="handoverPillConfig.stepCompleted"
      *ngIf="handoverPillConfig"
      [config]="handoverPillConfig"
    ></app-payment-run-step-pill>
    <app-payment-run-step-pill
      tid="payroll-approval-pill"
      [attr.data-step-complete]="approvalPillConfig.stepCompleted"
      [config]="approvalPillConfig"
    ></app-payment-run-step-pill>
    <app-payment-run-step-pill
      tid="payment-authorisation-pill"
      [attr.data-step-complete]="authorisationPillConfig.stepCompleted"
      *ngIf="bureauHandlesBacs"
      [config]="authorisationPillConfig"
    ></app-payment-run-step-pill>
  </div>
</div>
